import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchFormWithFilters from '../components/SearchFormWithFilters';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaStar } from 'react-icons/fa';
import defaultImage from '../images/default-image.jpg';



const SearchPage = () => {
  const [strutture, setStrutture] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  // Recupero parametri dalla query string
  const queryParams = new URLSearchParams(location.search);
  const nome_completo = queryParams.get('nome_completo') || "";
  const categoria = queryParams.get('categoria') || "";
  const tipologia = queryParams.get('tipologia') || "";
  const comune = queryParams.get('comune') || "";
  const prezzo = queryParams.get('prezzo') || "";
  const pos_interna = queryParams.get('pos_interna') || "";
  const pos_esterna = queryParams.get('pos_esterna') || "";
  const pos_aree_mt = queryParams.get('pos_aree_mt') || "";
  const classificazione = queryParams.get('classificazione') || "";
  const classificazioniValide = ["5 Stelle", "4 Stelle", "3 Stelle", "2 Stelle", "1 stella"];




  useEffect(() => {
    setLoading(true);

    // Costruzione URL richiesta API
    let url = `https://sist.aptbasilicata.it/api/strutture?page=${currentPage}`;



    // Aggiunta parametri di query se presenti
    const params = new URLSearchParams();
    if (nome_completo) params.append('nome_completo', nome_completo);
    if (categoria) params.append('categoria', categoria);
    if (tipologia) params.append('tipologia', tipologia);
    if (comune) params.append('comune', comune);
    if (prezzo) params.append('prezzo', prezzo);
    if (classificazione) params.append('classificazione', classificazione);
    if (pos_interna) params.append('pos_interna', pos_interna);
    if (pos_esterna) params.append('pos_esterna', pos_esterna);
    if (pos_aree_mt) params.append('pos_aree_mt', pos_aree_mt);

    if (params.toString()) {
      url += `&${params.toString()}`;
    }



    axios.get(url)
      .then((response) => {
        const data = response.data.structures;
        setStrutture(data.data || []);
        setTotalPages(data.last_page);
        setTotalResults(data.total);  // Imposta il numero totale dei risultati
        setLoading(false);
      })
      .catch((err) => {
        setError("Si è verificato un errore durante la ricerca.");
        setLoading(false);
      });
  }, [nome_completo, categoria, tipologia, comune, prezzo, pos_interna, pos_esterna, pos_aree_mt, classificazione, currentPage]);

  const handleCardClick = (id) => {
    navigate(`/strutture?id=${id}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };



  // Helper per trovare l'immagine di copertina
  const getCoverImage = (fotos) => {
    const coverImage = fotos?.find(foto => foto.is_cover === 1);
    return coverImage ? `https://sist.aptbasilicata.it/${coverImage.path}` : defaultImage;
  };


  return (

    <>
      <section className='apt-bg-color-2 pt-2 pb-3 bg-home mb-4'>
        <div className='container mt-4'>
          <div>
            <h1 className='title'>Dove dormire</h1>
            <p className='text-white fs-5'> Ricerca tutte le strutture ricettive della Basilicata</p>
          </div>
        </div>
      </section>

      <div className='container'>
        <div className='row'>

          <div className="col-md-4">

            <SearchFormWithFilters
              initialQuery={nome_completo}
              initialCategory={categoria}
              initialType={tipologia}
              initialComune={comune}
              initialPrezzo={prezzo}
              initialClassificazione={classificazione}
              initialPosInterna={pos_interna}
              initialPosEsterna={pos_esterna}
              initialPosAree={pos_aree_mt}
            />


          </div>

          <div className="col-md-8 mt-4">
            {/* Mostra il numero totale dei risultati */}
            {totalResults > 0 && (
              <span className="card-tipologia">
                Trovati {totalResults} risultati
              </span>
            )}

            {loading && <p>Caricamento...</p>}
            {error && <p>{error}</p>}
            {strutture.length > 0 ? (
              <div className="row mb-2">
                {strutture.map((struttura) => (
                  <div className="col-md-12" key={struttura.id}>
                    <div
                      className="card flex-md-row box-shadow mt-4 shadow-sm border border-none struttura-result"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleCardClick(struttura.id)}
                    >

                      {/* Immagine */}
                      <div
                        className="rounded tumb-image"
                        style={{
                          backgroundImage: `url(${getCoverImage(struttura.fotos)})`,
                        }}
                      />


                      <div className="card-body card-tipologia">
                        {/* Classificazione e dettagli */}

                        {(struttura.classificazione_alb === "Non soggetta a classificazione") ||

                          (struttura.classificazione_alb !== classificazioniValide) ? (

                          <span className='result_tipologia'> {struttura.tipologia || 'Non disponibile'} </span>
                        ) : (
                          struttura.classificazione_alb && <>{struttura.classificazione_alb}</>
                        )}



                        {struttura.classificazione_alb === "5 Stelle lusso" && (
                          <span className='result_tipologia' >
                            {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /><br />
                          </span>
                        )}


                        {struttura.classificazione_alb === "5 Stelle" && (
                          <span className='result_tipologia'>
                            {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /><br />
                          </span>
                        )}


                        {struttura.classificazione_alb === "4 Stelle" && (
                          <span className='result_tipologia'>
                            {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' /><br />
                          </span>
                        )}



                        {struttura.classificazione_alb === "3 Stelle" && (
                          <span className='result_tipologia'>
                            {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' /> <FaStar className='star' />
                          </span>
                        )}



                        {struttura.classificazione_alb === "2 Stelle" && (
                          <span className='result_tipologia'>
                            {struttura.classificazione_alb} <FaStar className='star' /> <FaStar className='star' />
                          </span >
                        )}


                        {struttura.classificazione_alb === "1 Stelle" && (
                          <span className='result_tipologia'>
                            {struttura.classificazione_alb} <FaStar className='star' />
                          </span>
                        )}
                        <h5 className="card-title fs-4 bold-text apt-color-1">{struttura.nome_completo}</h5>


                        <div className="d-flex flex-column gap-1">
                          <span><FaMapMarkerAlt className="apt-color-1" /> {struttura.indirizzo} {struttura.civico}, {struttura.cap} {struttura.rel_comune?.COMUNE || 'Non disponibile'}</span>
                          <span><FaPhoneAlt className="apt-color-1" /> {struttura.cell || struttura.tel || 'Non disponibile'}</span>
                          <span><FaEnvelope className="apt-color-1" /> {struttura.email || 'Non disponibile'}</span>
                        </div>
                        <p className="mt-3 card-tipologia"> Prezzo massimo: <span className="card-prezzo">{struttura.prezzi_max || 'Non disponibile'}</span> </p>

                        <div className="d-grid gap-1 d-md-block mt-auto">
                          <button
                            type="button"
                            className="btn btn-struttura mt-auto apt-bg-color-1 border-0"
                            onClick={() => handleCardClick(struttura.id)}
                          >
                            Visualizza struttura
                          </button>
                        </div>
                      </div>

                      <iframe
                        width="220px"
                        height="260px"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        title={struttura.denominazione}
                        src={`https://maps.google.it/maps?q=${struttura.indirizzo} ${struttura.rel_comune?.COMUNE} ${struttura.civico} &output=embed`}></iframe>

                    </div>
                  </div>
                ))}
              </div>
            ) : (
              !loading && <p>Nessuna struttura trovata.</p>
            )}

            {/* Paginazione */}
            <div className="pagination d-flex justify-content-center gap-1 mb-4 mt-4 p-4">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`btn btn-pagination ${currentPage === index + 1 ? 'btn-primary btn-md apt-bg-color-1 border-0' : 'btn-secondary btn-md border-0'}`}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchPage;
