import React, { useState, useEffect, useCallback } from 'react'; import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const SearchFormWithFilters = ({ initialQuery, initialComune }) => {
    const [nome_completo, setNomeCompleto] = useState(initialQuery || "");
    const [comune, setComune] = useState(initialComune || "");
    const [tipologia, setTipologia] = useState([]);
    const [prezzo, setPrezzo] = useState([]);
    const [classificazione, setClassificazione] = useState([]);
    const [pos_interna, setPosInterna] = useState([]);
    const [pos_esterna, setPosEsterna] = useState([]);
    const [pos_aree_mt, setPosAree] = useState([]);


    const [tipologieOptions, setTipologieOptions] = useState([]);
    const [prezzoOptions, setPrezzoOptions] = useState([]);
    const [classificazioneOptions, setClassificazioneOptions] = useState([]);
    const [pos_internaOptions, setPosInternaOptions] = useState([]);
    const [pos_esternaOptions, setPosEsternaOptions] = useState([]);
    const [pos_areeOptions, setPosAreeOptions] = useState([]);



    const navigate = useNavigate();

    const updateFilters = useCallback(debounce(() => {
        const params = new URLSearchParams();
        if (nome_completo.trim()) params.append('nome_completo', nome_completo.trim());
        if (comune.trim()) params.append('comune', comune.trim());
        if (tipologia.length) params.append('tipologia', tipologia.join(','));
        if (prezzo.length) params.append('prezzo', prezzo.join(','));
        if (classificazione.length) params.append('classificazione', classificazione.join(','));
        if (pos_interna.length) params.append('pos_interna', pos_interna.join(','));
        if (pos_esterna.length) params.append('pos_esterna', pos_esterna.join(','));
        if (pos_aree_mt.length) params.append('pos_aree_mt', pos_aree_mt.join(','));

        axios.get(`https://sist.aptbasilicata.it/api/strutture?${params.toString()}`)
            .then(response => {
                const data = response.data;
                setTipologieOptions(data.tipologie || []);
                setPrezzoOptions(data.prezzi || []);
                setClassificazioneOptions(data.classificazioni || []);
                setPosInternaOptions(data.pos_interne || []);
                setPosEsternaOptions(data.pos_esterne || []);
                setPosAreeOptions(data.pos_aree || []);
            })
            .catch(error => {
                console.error("Errore nel recupero delle opzioni per i filtri:", error);
            });
    }, 200), [nome_completo, comune, tipologia, prezzo, classificazione, pos_interna, pos_esterna, pos_aree_mt]);

    useEffect(() => {
        updateFilters();
    }, [updateFilters]);



    const handleCheckboxChange = (setter, value) => {
        setter(prevState =>
            prevState.includes(value)
                ? prevState.filter(item => item !== value)
                : [...prevState, value]
        );
    };

    // const handleCheckboxChange = (setter, value) => {
    //     setter(prevState => {
    //         const index = prevState.indexOf(value);
    //         if (index >= 0) {
    //             return prevState.filter(item => item !== value);
    //         } else {
    //             return [...prevState, value];
    //         }
    //     });
    // };

    const handleSearch = (e) => {

        e.preventDefault();
        const queryParams = new URLSearchParams();
        if (nome_completo.trim()) queryParams.append("nome_completo", nome_completo.trim());
        if (comune.trim()) queryParams.append("comune", comune.trim());
        if (tipologia.length) queryParams.append("tipologia", tipologia.join(','));
        if (prezzo.length) queryParams.append("prezzo", prezzo.join(','));
        if (classificazione.length) queryParams.append("classificazione", classificazione.join(','));
        if (pos_interna.length) queryParams.append("pos_interna", pos_interna.join(','));
        if (pos_esterna.length) queryParams.append("pos_esterna", pos_esterna.join(','));
        if (pos_aree_mt.length) queryParams.append("pos_aree_mt", pos_aree_mt.join(','));
        navigate(`/search?${queryParams.toString()}`);

    };

    const resetFilters = () => {
        setNomeCompleto("");
        setComune("");
        setTipologia([]);
        setPrezzo([]);
        setClassificazione([]);
        setPosInterna([]);
        setPosEsterna([]);
        setPosAree([]);
        navigate(`/search`);
    };





    return (
        <form className="d-flex flex-column mb-0 p-0 input-group-lg bg-filter p-4 col-md-12" role="search" onSubmit={handleSearch}>
            <div className="row">
                <div className="col-md-12 mb-2 input-group-md">
                    <h1 className='apt-color-1 bold-text fs-3 mb-3'> Cerca per:</h1>

                    <span className='apt-color-1 bold-text fs-5'>Destinazione</span>
                    <input
                        className="form-control col-md-6"
                        type="text"
                        placeholder="Cerca per comune..."
                        value={comune}
                        onChange={(e) => setComune(e.target.value)}
                    />
                </div>
                <div className="col-md-12 mb-2 input-group-md">

                    <span className='apt-color-1 bold-text fs-5'>Denominazione</span>
                    <input
                        className="form-control col-md-6"
                        type="text"
                        placeholder="Cerca per denominazione..."
                        value={nome_completo}
                        onChange={(e) => setNomeCompleto(e.target.value)}
                    />
                </div>
            </div>


            <hr></hr>

            <h1 className='apt-color-1 bold-text fs-3 mb-3'> Filtra per:</h1>


            <div className="mb-2">
                <h3 className='apt-color-1 bold-text fs-6'>Tipologia ricettiva</h3>

                {tipologieOptions.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`tipologia-${index}`}
                            checked={tipologia.includes(option.value)}
                            onChange={() => handleCheckboxChange(setTipologia, option.value)}
                        />
                        <label className="form-check-label d-flex justify-content-between w-100" htmlFor={`tipologia-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted">({option.count})</span>
                        </label>
                    </div>
                ))}
            </div>

            <div className="mb-2">
                <h3 className='apt-color-1 bold-text fs-6'>Prezzo</h3>
                {prezzoOptions.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`prezzo-${index}`}
                            checked={prezzo.includes(option.value)}
                            onChange={() => handleCheckboxChange(setPrezzo, option.value)}
                        />

                        <label className="form-check-label d-flex justify-content-between w-100" htmlFor={`prezzo-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted">({option.count})</span>
                        </label>

                    </div>
                ))}
            </div>

            <div className="mb-4">
                <h3 className='apt-color-1 bold-text fs-6'>Stelle</h3>
                {classificazioneOptions.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`classificazione-${index}`}
                            checked={classificazione.includes(option.value)}
                            onChange={() => handleCheckboxChange(setClassificazione, option.value)}
                        />


                        <label className="form-check-label d-flex justify-content-between w-100" htmlFor={`classificazione-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted">({option.count})</span>
                        </label>


                    </div>
                ))}
            </div>


            <div className="mb-4">
                <h3 className='apt-color-1 bold-text fs-6'>Posizione (Aree Matera) </h3>
                {pos_areeOptions.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`pos_aree_mt-${index}`}
                            checked={pos_aree_mt.includes(option.value)}
                            onChange={() => handleCheckboxChange(setPosAree, option.value)}
                        />

                        <label className="form-check-label d-flex justify-content-between w-100" htmlFor={`pos_aree_mt-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted">({option.count})</span>
                        </label>
                    </div>
                ))}
            </div>



            <div className="mb-4">
                <h3 className='apt-color-1 bold-text fs-6'>Posizione interna alla destinazione</h3>
                {pos_internaOptions.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`pos_interna-${index}`}
                            checked={pos_interna.includes(option.value)}
                            onChange={() => handleCheckboxChange(setPosInterna, option.value)}
                        />
                        <label className="form-check-label d-flex justify-content-between w-100" htmlFor={`pos_interna-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted">({option.count})</span>
                        </label>
                    </div>
                ))}
            </div>


            <div className="mb-4">
                <h3 className='apt-color-1 bold-text fs-6'>Posizione esterna alla destinazione </h3>
                {pos_esternaOptions.map((option, index) => (
                    <div key={index} className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={`pos_esterna-${index}`}
                            checked={pos_esterna.includes(option.value)}
                            onChange={() => handleCheckboxChange(setPosEsterna, option.value)}
                        />
                        <label className="form-check-label d-flex justify-content-between w-100" htmlFor={`pos_esterna-${index}`}>
                            <span>{option.value}</span>
                            <span className="text-muted">({option.count})</span>
                        </label>
                    </div>
                ))}
            </div>





            <button className="btn search-btn btn-lg apt-bg-color-1 d-flex justify-content-center" type="submit">
                Cerca
            </button>

            <button
                type="button"
                className="filtri pb-4 mt-3"
                onClick={resetFilters}
            >
                Azzera filtri
            </button>
        </form>
    );
};

export default SearchFormWithFilters;
