// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Contatti from './pages/Contatti';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer';
import '../src/App.css'
import SearchPage from './components/SearchPage';
// import Redirect from './components/Redirect';
import Details from './components/Details';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';



function App() {
  return (

    <div className="d-flex flex-column min-vh-100">
    <Router>
      <Navbar />

      <div>
        <Routes>
          <Route path="/" element={<SearchPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/strutture" element={<Details />} />
          {/* <Route path="/about" element={<About />} /> */}
          {/* <Route path="/Sito istituzionale" element={<Redirect url="https://www.esempio.com" />} /> */}
          <Route path="/contatti" element={<Contatti />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy/>} />
        </Routes>
      </div>
      <Footer/>
    </Router>
    </div>

    

  );
}

export default App;
